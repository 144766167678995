import React from 'react'
import DressUpContainer from './dressupContainer'

const AppContainer = () => {

    return (
        <>
            <DressUpContainer />
        </>
    )
}

export default AppContainer
