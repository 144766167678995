import logo from './logo.svg';
import './App.css';
import AppContainer from './containers/app';

function App() {
  return (
    <>
    <AppContainer></AppContainer>
    </>
  );
}

export default App;
